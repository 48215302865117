/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react"
import { jsx, Flex, Grid } from "theme-ui"

import { FaFont } from "./fa"

type NoteProps = {
	cr: number
	cd: number
	dc: number
	da: number
	remove: any
	i: number
	t: any
	label: string
}

const Note = ({
	cr, cd, dc, da, remove, i, t, label
}: NoteProps) => (<Flex sx={{
	justifyContent: `space-between`,
	border: `solid 1px`,
	borderColor: `border`,
	borderRadius: `.5em`,
	py: 1,
	px: 2,
	mb: 3,
}}>
	<Grid gap={1} columns={2} sx={{fontSize: [1,1,2],}}>
		<div>{t(`CRIT Rate`)}</div><div sx={{textAlign: `center`,}}>{cr}</div>
		<div>{t(`CRIT DMG`)}</div><div sx={{textAlign: `center`,}}>{cd}</div>
		<div>{t(`DMG (CRIT)`)}</div><div sx={{textAlign: `center`,}}>{dc}</div>
	</Grid>
	<div sx={{my: `auto`,}}>
		<div>{t(`DMG (Avg.)`)}</div>
		<div sx={{textAlign: `center`, fontSize: [3,3,4],}}>{da}</div>
	</div>
	<Flex sx={{
		flexDirection: `column`,
		justifyContent: `space-between`,
		fontSize: [1,1,2],
		textAlign: `right`,
	}}>
		<div onClick = {()=>remove(i)} sx={{cursor: `pointer`,}}>
			<FaFont i="xmark" color="red" />
		</div>
		<div>{label}</div>
	</Flex>
</Flex>)

export default Note
