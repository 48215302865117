/** @jsx jsx */
import React, { useEffect, useRef, useState } from "react"
import { jsx, Flex, Grid } from "theme-ui"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	Title,
	Tooltip,
	Legend,
	ScatterController,
} from "chart.js"
import { Chart } from "react-chartjs-2"

import { FaFont } from "./fa"

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	Title,
	Tooltip,
	Legend,
	ScatterController
)

type NoteProps = {
	data: {
		status: string
		base: number
		add: number
		cr: number
		cd: number
		avg: number
	}
	remove: any
	i: number
	t: any
	label: string
}

export const Note = ({
	data, status, remove, i, t, label
}: NoteProps) => (<Flex sx={{
	justifyContent: `space-between`,
	border: `solid 1px`,
	borderColor: `border`,
	borderRadius: `.5em`,
	py: 1,
	px: 2,
	mb: 3,
}}>
	<Grid gap={1} columns={3} sx={{fontSize: [1,1,2],}}>
		<div>{t(data.status)}</div><div sx={{textAlign: `right`,}}>{data.base}</div><div>+{data.add}</div>
		<div>{t(`C Rate`)}</div><div sx={{textAlign: `right`,}}>{data.cr}</div><div></div>
		<div>{t(`C DMG`)}</div><div sx={{textAlign: `right`,}}>{data.cd}</div><div></div>
	</Grid>
	<div sx={{my: `auto`,}}>
		<div sx={{textAlign: `center`,}}>{t(`Avg.`)}</div>
		<div sx={{textAlign: `center`, fontSize: [3,3,4],}}>{data.avg}</div>
	</div>
	<Flex sx={{
		flexDirection: `column`,
		justifyContent: `space-between`,
		fontSize: [1,1,2],
		textAlign: `right`,
	}}>
		<div onClick = {()=>remove(i)} sx={{cursor: `pointer`,}}>
			<FaFont i="xmark" color="red" />
		</div>
		<div>{label}</div>
	</Flex>
</Flex>)

type NotesChartProps = {
	data: {
		avg: number
		crit: number
	}[]
	labels: string[]
	legends: string[]
	notes: any
}

export const NotesChart = ({
	data, labels, legends, notes
}: NotesChartProps) => {
	const parent = useRef()
		, chartData = {
			labels: labels,
			datasets: [
				{
					type: `bar`,
					label: legends[0],
					data: data.map(e => Number(e.avg)),
					borderColor: `#fff`,
					backgroundColor: `rgba(16,14,35,0.2)`,
					borderRadius: 7,
				},
				{
					type: `scatter`,
					label: legends[1],
					data: data.map(e => Number(e.crit)),
					borderColor: `#ff0`,
					backgroundColor: `rgba(16,14,35,0.2)`,
					pointStyle: `rectRot`,
					pointRadius: 8,
				},
			],
		}
		, chartOptions = {
			maintainAspectRatio: false,
			indexAxis: `y`,
			elements: {
				bar: {
					borderWidth: 2,
				},
			},
			plugins: {
				legend: {
					position: `top`,
					labels: {
						color: `white`,
						usePointStyle: true,
					},
				},
				tooltip: {
					enabled: false,
				},
			},
			scales: {
				y: {
					ticks: {
						color: `white`,
						font: {
							size: 16,
						},
					},
					border: {
						color: `white`,
					},
					grid: {
						display: false,
					},
				},
				x: {
					ticks: {
						color: `#68699B`,
					},
					grid: {
						color: `rgba(104,105,155,0.1)`,
					},
				},
			},
		}
	useEffect(()=>{
		parent.current.style.height = notes ? notes.clientHeight +`px` : `100px`
	},[data])
	return (<div ref={parent} sx={{ position: `relative`, }}>
		<Chart
			options={chartOptions}
			data={chartData}
		/>
	</div>)
}
