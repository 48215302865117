/** @jsx jsx */
import React, { useState, useRef } from "react"
import { jsx, Flex, Input, Button } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Note from "../components/@avg-dmg-notes"
import { NotesChart } from "../components/@atk-crit-notes"

const Page = () => {
	const {t} = useTranslation()
		, [critRate, setCritRate] = useState()
		, [critDmg, setCritDmg] = useState()
		, [dmg, setDmg] = useState()
		, [arr,setArr] = useState([])
		, notes = useRef()
		, click = () => {
			setArr([{
				"cr": critRate,
				"cd": critDmg,
				"crit": dmg,
				"avg": avg(critRate/100, critDmg/100, dmg),
			}].concat(arr))
		}
		, remove = (index) => {
			setArr(arr.filter((e,i) => i !== index))
		}
	return (
		<Layout title={t(`Average Damage Calculator`)}>
			<Columns>
				<Flex sx={{
					"> div": {width: `100%`},
					"input": {
						fontSize: 4,
						py: 0,
						px: 2,
						ml: 3, mt: 1, mb: 3,
					},
				}}><div>
					<h3>{t(`In The Status`)}</h3>
					{t(`CRIT Rate`)}
					<Input type="number" onChange={e=>setCritRate(Number(e.target.value))} sx={{width: `4.2em`,}} />
					{t(`CRIT DMG`)}
					<Input type="number" onChange={e=>setCritDmg(Number(e.target.value))} sx={{width: `4.2em`,}} />
				</div><div>
					<h3>{t(`On Field`)}</h3>
					{t(`Damage (CRIT)`)}
					<Input type="number" onChange={e=>setDmg(Number(e.target.value))} sx={{width: `5.8em`,}} />
				</div></Flex>
				<div>{
					!(critRate && critDmg && dmg) ? null
					: (<>
						<h2 sx={{
							mt: `0 !important`,
						}}>{t(`Result`)}</h2>
						<p>{t(`Damage (Avg.)`)} : <span sx={{
							fontSize: 4,
						}}>{avg(critRate/100, critDmg/100, dmg)}</span></p>
						<Button onClick={click}>{t(`Keep Note`)}</Button>
					</>)
				}</div>
			</Columns>
			{ !(arr[0]) ? null : (<>
				<h2>{t(`Note`)}</h2>
				<Columns mb={0}>
					<div>
						<div ref={notes}>{arr.map((e,i)=>(<React.Fragment key={i}>
							<Note
								cr={e.cr} cd={e.cd}
								dc={e.crit} da={e.avg}
								t={t} remove={remove} i={i}
								label={`#`+ (arr.length - i)}
							/>
						</React.Fragment>))}</div>
					</div>
					<NotesChart
						data={arr}
						labels={arr.map((e,i)=>`#`+ (arr.length - i))}
						legends={[t(`DMG (Avg.)`), t(`DMG (CRIT)`)]}
						notes={notes.current}
					/>
				</Columns>
			</>)}
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","avg-dmg"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const avg = (critRate, critDmg, dmg) => (
		(dmg * (1+clip01(critRate)*critDmg) / (1+critDmg)).toFixed(0)
	)
	, clip01 = num => (num < 0) ? 0 : ((num > 1) ? 1 : num)
